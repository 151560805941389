<template>
  <div class="app-form">
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">UCS Base URL</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" placeholder="e.g. http://8.8.8.8:8888" v-model="setting.backer_main_url">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">UCS API URL</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" placeholder="e.g. api/v1/" v-model="setting.backer_api_url">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">UCS SID</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="setting.backer_sid">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">UCS CORP ID</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="setting.backer_corporate">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">UCS REST ID</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="setting.backer_restorane">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">R-keeper CRM Base URL</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" placeholder="e.g. http://8.8.8.8:8888" v-model="setting.rkeeper_crm_base_url">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">R-keeper CRM TCP Port</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" placeholder="e.g. 8080" v-model="setting.rkeeper_crm_port">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">R-keeper CRM Классификатор</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="setting.rkeeper_crm_identifier">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">R-keeper CRM Бонусные счета</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text"  placeholder="e.g. Бонусный счет,Скидка" v-model="setting.rkeeper_crm_bonus_account_names">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">R-keeper R7API Base URL</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" placeholder="e.g. http://8.8.8.8:8888" v-model="setting.rkeeper_crm_r7api_base_url">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">R-keeper R7API TCP Port</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" placeholder="e.g.  8080" v-model="setting.rkeeper_crm_r7api_port">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">R-keeper R7API username</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="setting.rkeeper_crm_r7api_username">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">R-keeper R7API password</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="setting.rkeeper_crm_r7api_password">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">SMS логин</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" placeholder="e.g. user@damain" v-model="setting.backer_sms_login">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">SMS токен</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="setting.backer_sms_token">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">SMS CB URL</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="setting.backer_sms_callback_url">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">SMS Sign</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" v-model="setting.backer_sms_sign">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Telegram ids supports</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" type="text" placeholder="123,234,345" v-model="setting.tg_supports">
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Разрешить регистрацию</label>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <div class="select is-fullwidth">
              <select expanded v-model="setting.allow_signup_user">
                <option
                  v-for="option in state_yesno"
                  :value="option.id"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Одна сессия</label>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <div class="select is-fullwidth">
              <select expanded v-model="setting.one_user_is_one_session">
                <option
                  v-for="option in state_yesno"
                  :value="option.id"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Уведомлять</label>
      </div>
      <div class="field-body">
        <div class="field is-narrow">
          <div class="control">
            <div class="select is-fullwidth">
              <select expanded v-model="setting.notify">
                <option
                  v-for="option in state_yesno"
                  :value="option.id"
                  :key="option.id">
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">Legal Info</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <b-input maxlength="200" type="textarea" v-model="setting.legal_info"></b-input>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label">
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <button class="button is-primary" @click="doUpdate()">
            Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { getSettings, updateSettings } from '@/api/setting'
import { state_check, state_show, state_yesno, EventBus } from '@/utils'

export default {
  name: 'Setting',
  components: {
    //
  },
  props: {
    //
  },
  data: function () {
    return {
      state_check: state_check,
      state_show: state_show,
      state_yesno: state_yesno,

      setting: {

      }
    }
  },
  created: function() {
    //
  },
  computed: {
    //
  },
  beforeMount: function(){
    //
  },
  mounted: function(){
    getSettings(this.$store.state.jwt_user)
      .then(response => {
        let data = response.data;
        this.setting = data.data

      })
      .catch(
        this.data = {}
      )
  },
  methods: {
    doUpdate () {
      let $this = this
      this.$buefy.dialog.confirm({
        // message: $this.$i18n.t('ui.admin.settings.confirm'),
        // cancelText: $this.$i18n.t('form.no'),
        // confirmText: $this.$i18n.t('form.yes'),
        message: "Вы уверены?",
        cancelText: "Нет",
        confirmText: "Да",
        onConfirm: () => {
          
          updateSettings($this.setting, this.$store.state.jwt_user).then(() =>
            {
              EventBus.$emit('successUpdateSettings')
            }
          )        

        }
      })
    },
  }
}
</script>

<style scoped>
  .app-form {
    text-align: left;
  }
</style>
<style>
</style>